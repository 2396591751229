import { signIn, signOut, useSession } from "next-auth/client";
import styled from "styled-components";
import { Restrictor } from "./restrictor";

const Menu = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

const Header = () => {
  const [session, loading] = useSession();
  const action = session ? signOut : signIn;
  const text = loading ? "..." : session ? "Sign Out" : "Sign In";
  return (
    <Restrictor>
      <Menu>
        <button disabled={loading} onClick={action}>
          {text}
        </button>
      </Menu>
    </Restrictor>
  );
};
export default Header;
