import styled, { createGlobalStyle } from "styled-components";
import { Provider } from "next-auth/client";
import Header from "components/header";

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    padding-top: 10px;
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }
`;

function MyApp({ Component, pageProps, err }) {
  return (
    <>
      <GlobalStyle />
      <Provider>
        <Header />
        <Component {...pageProps} err={err} />
      </Provider>
    </>
  );
}

export default MyApp;
